import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
// import "assets/scss/argon-design-system-react.scss";
import "assets/scss/greenhub.scss";

//Routes
import indexRoutes from "routes";

const AppRoot = () => (
    <BrowserRouter>
        <Switch>
            {indexRoutes.map((prop, key) => {
                return (
                    <Route
                        path={prop.path}
                        key={key}
                        component={prop.component}
                        exact
                    />
                );
            })}
            <Redirect to="/" />
        </Switch>
    </BrowserRouter>
);

ReactDOM.render(<AppRoot />, document.getElementById("root"));

// ReactDOM.render(
//   <BrowserRouter>
//     <Switch>
//       <Route path="/" exact render={props => <HomePage {...props} />} />
//       <Route
//         path="/landing-page"
//         exact
//         render={props => <Landing {...props} />}
//       />
//       <Route path="/login-page" exact render={props => <Login {...props} />} />
//       <Route
//         path="/profile-page"
//         exact
//         render={props => <Profile {...props} />}
//       />
//       <Route
//         path="/register-page"
//         exact
//         render={props => <Register {...props} />}
//       />
//       <Redirect to="/" />
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
