/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container fluid className="p-3 p-md-5">
            <Row>
              <Col lg="4">
                <a href="/" className="logo">
                  <img
                    alt="logo"
                    src={require("assets/img/brand/logo_1.png")}
                  />
                </a>
                <ul className="list-unstyled">
                  <li>
                    <a href="/"> <i className="fa fa-building"></i> CÔNG TY CP CÔNG NGHỆ GREENBOT</a>
                  </li>
                  <li>
                    <a href="/"><i className="fa fa-location-arrow"></i><span>Địa chỉ :</span> 152 Đường số 6, Khu phố 2, Phường Phú Hữu, Quận 9, TP.HCM</a>
                  </li>
                  <li>
                    <a href="mailto:info@greenbot.vn"><i className="fa fa-envelope-o"></i><span>Email :</span> info@greenbot.vn</a>
                  </li>
                  <li>
                    <a href="tel:0905355688"><i className="fa fa-phone"></i><span>Hotline :</span> 0905 355 688</a>
                  </li>
                  <li>
                    <a href="/"><i className="fa fa-certificate"></i><span></span>Giấy chứng nhận Đăng ký Kinh doanh số <strong>0314592854</strong> do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp ngày 24/08/2019</a>
                  </li>
                </ul>
              </Col>
              <Col lg="5">
                <Row>
                  <Col lg="6">
                    <h6 className="text-uppercase">TÀI KHOẢN</h6>
                    <ul className="list-unstyled">
                      <li><a href="#">Tài khoản của tôi</a></li>
                      <li><a href="#">Điểm thưởng của bạn</a></li>
                      <li><a href="#">Giỏ hàng</a></li>
                    </ul>
                    <h6 className="text-uppercase">Thông tin</h6>
                    <ul className="list-unstyled">
                      <li><a href="#">Thông tin về GreenHub</a></li>
                    </ul>
                  </Col>
                  <Col lg="6">
                    <h6 className="text-uppercase">Hỗ trợ</h6>
                    <ul className="list-unstyled">
                      <li><a href="#">Điều khoản và quy định chung</a></li>
                      <li><a href="#">Hình thức thanh toán</a></li>
                      <li><a href="#">Vận chuyển và giao nhận</a></li>
                      <li><a href="#">Đổi trả và hoàn tiền</a></li>
                      <li><a href="#">Bảo mật thông tin</a></li>
                      <li><a href="#">Chính sách điểm thưởng</a></li>
                      <li><a href="#">Hợp tác</a></li>
                      <li><a href="#">Liên hệ</a></li>
                    </ul>
                    <h6 className="text-uppercase">Affiliate Marketing</h6>
                    <ul className="list-unstyled">
                      <li><a href="#">Đăng ký Affiliate Partner</a></li>
                      <li><a href="#">Đăng nhập Affiliate Partner</a></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col lg="3">
                <h6 className="text-uppercase">Kết nối với GreenHub</h6>
                <ul className="list-inline">
                  <li className="list-inline-item"><a href="#"><i className="fa fa-facebook-square fa-lg" /></a></li>
                  <li className="list-inline-item"><a href="#"><i className="fa fa-instagram fa-lg" /></a></li>
                  <li className="list-inline-item"><a href="#"><i className="fa fa-twitter-square fa-lg" /></a></li>
                </ul>

                <Row>
                  <Col xs="6">
                    <a href="/"><img className="img-fluid img-certificate" src="https://theme.hstatic.net/1000254356/1000453427/14/dathongbao.png?v=449"/></a>
                  </Col>
                  <Col xs="6">
                    <a href="/"><img src=""/></a>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Container>
          <div className="footer-copyright">
            <Container fluid className="px-md-5">
              <Row className=" align-items-center justify-content-md-between">
                <Col md="6">
                  <div className=" copyright">
                    Copyright © {new Date().getFullYear()}{" "}
                      GREENHUB.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
