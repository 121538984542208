import React, { Component } from "react";

// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Row,
  Col,
  Progress,
  Button
} from "reactstrap";

import CountDownCustom from "../../../components/CountDownCustom";

class CampaignFeatured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleValue: 100.0,
    }

  }
  componentDidMount() {
    // slider1 init
    var slider1 = this.refs.slider1;
    Slider.create(slider1, {
      start: [0.0],
      connect: [true, false],
      step: 0.01,
      range: { min: 1, max: 50 },
      pips: {
        mode: 'positions',
        values: [0, 18, 60, 100],
        density: 4
      }
    }).on(
      "update",
      function(values, handle) {
        this.setState({ simpleValue: values[0] });
      }.bind(this)
    );


  }

  render() {
    return(
      <Row>
        <Col lg="6" xs="12" className="p-sm-0">
          <div className="event-item">
            <h6 className="text-uppercase">Chiến dịch đang diễn ra</h6>
            <Row>
              <Col sm="6" xs="12">
                <div className="event-item__bg-img" style={{backgroundImage: `url("https://product.hstatic.net/1000254356/product/qr_656561c42e4940ceb89a4be091da9b5d_large.jpg")`}}></div>
              </Col>
              <Col sm="6" xs="12" className="event-item__time-place">
                <h5 className="font-weight-bold"><a href="/">SẦU RIÊNG CHÍN TỰ NHIÊN - SẦU RIÊNG RI 6</a></h5>
                <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i>
                <ul className="list-unstyled">
                  <li><i className="fa fa-clock-o"></i><small>Ngày giao : đầu tháng 5</small></li>
                  <li><i className="fa fa-map-marker"></i><small>Khu vực :  Sài Gòn - Huế - Hà Nội</small></li>
                </ul>
                <small><strong>Chiến dịch sẽ kết thúc trong</strong></small>
                <div className="event-item__countdown">
                  <CountDownCustom />
                </div>
              </Col>
            </Row>
            <div className="progress-wrapper">
            <div className="progress-info" style={{left: "60%"}}>
              <div className="progress-percentage">
                <span>60%</span>
              </div>
            </div>
            <Progress max="100" value="60" />
          </div>
          </div>
        </Col>
        <Col lg="2" xs="12" className="p-sm-0">
          <div className="event-amount">
            <div className="text-center event-amount__quantity"><b>Số lượng: 1</b></div>
            <div className="event-amount__description">
              <p>
            <b style={{color: "red"}}>Vui lòng đặt trên 2kg</b> vì 1 trái Sầu Riêng Ri6 trung bình là 2-4kg. GreenHub sẽ gọi trước khi giao để xác nhận khối lượng thực tế.</p>
  <p style={{textDecoration: "underline", color: "red"}}>
  Hàng hư, hàng sượng FoodMap sẽ ĐỔI HÀNG MIỄN PHÍ!</p>

  <p style={{color: "#1abc9c"}}><b>Giá: 145,000đ/kg</b></p>
  <p style={{color: "#f00"}}><b>Trên 10kg: 135,000đ/kg</b></p>
  <p>Trên 500,000đ <b>miễn phí giao</b> </p>
            </div>
          </div>
        </Col>
        <Col lg="4" xs="12" className="event-price-ranger">
          <div className="text-center mb-4 event-price-ranger__price"><strong>Giá: 145,000₫ / kg</strong></div>
          <div className="input-slider-container">
            <div className="slider" ref="slider1" />
            <Row className="mt-5 d-none">
              <Col xs="6">
                <span className="range-slider-value">
                  {this.state.simpleValue}
                </span>
              </Col>
            </Row>
          </div>

          <Button color="primary" block className="mt-5">Tìm hiểu thêm</Button>
        </Col>
      </Row>
    )
  }
}

export default CampaignFeatured;