import React from 'react';
import Swiper from 'react-id-swiper';

import 'react-id-swiper/lib/styles/css/swiper.css';


const Default = () => {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-white.swiper-button-next',
        prevEl: '.swiper-button-white.swiper-button-prev',
      }
    }
    return (
        <Swiper {...params}>
            <div style={{maxHeight: '100vh'}}><img className="img-fluid" src={require("assets/img/slider/slider1.jpg")} alt="" /></div>
            <div style={{maxHeight: '100vh'}}><img className="img-fluid" src={require("assets/img/slider/slider2.jpg")} alt="" /></div>
            <div style={{maxHeight: '100vh'}}><img className="img-fluid" src={require("assets/img/slider/slider3.jpg")} alt="" /></div>
            <div style={{maxHeight: '100vh'}}><img className="img-fluid" src={require("assets/img/slider/slider4.jpg")} alt="" /></div>
            <div style={{maxHeight: '100vh'}}><img className="img-fluid" src={require("assets/img/slider/slider5.jpg")} alt="" /></div>
            <div style={{maxHeight: '100vh'}}><img className="img-fluid" src={require("assets/img/slider/slider6.jpg")} alt="" /></div>

        </Swiper>
    )
};
export default Default;