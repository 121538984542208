import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Badge,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button
} from "reactstrap";

class NavbarCustom extends React.Component {
  state = {};
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container fluid>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/logo_1_white.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar_global">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/logo_1.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="d-lg-none header-search header-search--mobile">
                  <InputGroup>
                    <Input placeholder="Tìm kiếm ..." />
                    <InputGroupAddon addonType="append"><Button color="primary"><i className="fa fa-search"></i></Button></InputGroupAddon>
                  </InputGroup>
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <NavItem>
                    <NavLink href="/">Trang chủ</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/">Chiến dịch</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/">Liên hệ</NavLink>
                  </NavItem>
                </Nav>
                <Nav className="d-none d-lg-block mx-auto header-search">
                  <InputGroup>
                    <Input placeholder="Tìm kiếm ..." />
                    <InputGroupAddon addonType="append"><Button color="primary"><i className="fa fa-search"></i></Button></InputGroupAddon>
                  </InputGroup>
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                  <UncontrolledDropdown nav className="menu-language">
                    <DropdownToggle nav>
                      <img src={require("assets/img/languages/vn.png")} alt="" />
                    </DropdownToggle>
                    {/* <DropdownMenu right>
                      <DropdownItem>
                        <img src={require("assets/img/languages/en.png")} alt="" />
                      </DropdownItem>
                    </DropdownMenu> */}
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav className="nav-link-icon">
                      <i className="ni ni-circle-08" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        User
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem to="/" tag={Link}>
                        Đăng nhập
                      </DropdownItem>
                      <DropdownItem to="/" tag={Link}>
                        Đăng ký
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon link-cart"
                      href="/"
                      target="_blank"
                    >
                      <i className="ni ni-cart" />
                      <Badge color="primary">4</Badge>
                    </NavLink>

                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default NavbarCustom;
