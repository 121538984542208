import HomePage from "views/HomePage/HomePage";
import LandingPage from "views/examples/Landing.jsx";
import LoginPage from "views/examples/Login.jsx";
import ProfilePage from "views/examples/Profile.jsx";
import RegisterPage from "views/examples/Register.jsx";

var indexRoutes = [
    { path: "/landing-page", name: "LandingPage", component: LandingPage },
    { path: "/profile-page", name: "ProfilePage", component: ProfilePage },
    { path: "/home", name: "HomePage", component: HomePage },
    { path: "/login-page", name: "LoginPage", component: LoginPage },
    { path: "/register-page", name: "LoginPage", component: RegisterPage },
    { path: "/", name: "HomePage", component: HomePage }
];

export default indexRoutes;