import React, { Component } from "react";

import {
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  Progress
} from 'reactstrap';

class ItemCampaign extends Component {

    render() {
      const { item } = this.props;
      let item_progress = item.progress;
      let percentLeft = 0;

      if(item_progress === 0) {
        percentLeft = 0;
      }
      else if(item_progress > 0 && item_progress < 50) {
        percentLeft = item_progress + "%";
      }
      else if (item_progress >= 50 && item_progress < 90) {
        percentLeft = `calc(${item_progress}% - 19px)`;
      }
      else if(item_progress <= 100) {
        percentLeft = `calc(${item_progress}% - 38px)`;
      }
      return(
        <Card className="product-item">
          <a href="/"><div className="bg-product" style={{backgroundImage: `url(${item.img})`}}></div></a>
          <div className="progress-wrapper">
            <div className="progress-info" style={{left: percentLeft}}>
              <div className="progress-percentage">
                <span>{item.progress}%</span>
              </div>
            </div>
            <Progress max="100" value={item.progress} />
          </div>
          <CardBody>
            <CardTitle className="text-truncate text-center text-uppercase">{item.title}</CardTitle>
            <CardText tag="div" className="text-center product-item__content">
              <div className="font-weight-bolder product-item__price">{item.price}</div>
              <hr/>
              <div className="font-weight-bolder product-item__description">{item.description}</div>
            </CardText>
            <CardText className="text-center">
              <small className="text-muted">Bắt đầu: {item.start_time}</small><br/>
              <small className="text-muted">Kết thúc: {item.end_time}</small>
            </CardText>
          </CardBody>
          <Button color="primary">Tìm hiểu thêm</Button>
        </Card>
      )
    }
}

export default ItemCampaign;