import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import NavbarHeader from "components/Navbars";
import Footer from "components/Footers";

//Sections
import Slider from  "./Sections/Slider";
import ItemCampaign from  "./Sections/ItemCampaign";
import CampaignFeatured from "./Sections/CampaignFeatured";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state =  {
      products: [
        {
          id: "product_001",
          title: "CAM KIẾN VÀNG HỮU CƠ",
          img: "https://product.hstatic.net/1000254356/product/1_7dd2538fb942432985ee5f50344c17ee_large.jpg",
          price: "99.000 đ/ kg",
          description: "Vui lòng đặt trước trên 2 kg vì 1 trái sầu riêng Ri6 trung bình là 2-4kg. GreenHub sẽ gọi trước khi đến",
          start_time: "20/08/2019",
          end_time: "30/08/2019",
          progress: 60
        },
        {
          id: "product_002",
          title: "SẦU RIÊNG CHÍN TỰ NHIÊN - SẦU RIÊNG RI 6",
          img: "https://product.hstatic.net/1000254356/product/qr_656561c42e4940ceb89a4be091da9b5d_large.jpg",
          price: "199.000 đ/ kg",
          description: "Vui lòng đặt trước trên 2 kg vì 1 trái sầu riêng Ri6 trung bình là 2-4kg. GreenHub sẽ gọi trước khi đến",
          start_time: "20/08/2019",
          end_time: "30/08/2019",
          progress: 100
        },
        {
          id: "product_003",
          title: "MẬT ONG TƯƠI - RỪNG TRÀM THUẬN BÌNH, LONG AN",
          img: "https://product.hstatic.net/1000254356/product/ben_trong_thung_ong_result_50fac1f7a2fb4e60b9099d2c9f76ffa7_large.jpg",
          price: "299.000 đ/ kg",
          description: "Vui lòng đặt trước trên 2 kg vì 1 trái sầu riêng Ri6 trung bình là 2-4kg. GreenHub sẽ gọi trước khi đến",
          start_time: "20/08/2019",
          end_time: "30/08/2019",
          progress: 0
        },
        {
          id: "product_004",
          title: "BƠ 034 - LIÊN KẾT NÔNG DÂN LÂM ĐỒNG",
          img: "https://product.hstatic.net/1000254356/product/111_95d5118ed3184d359b87500146b1b798_large.jpg",
          price: "99.000 đ/ kg",
          description: "Vui lòng đặt trước trên 2 kg vì 1 trái sầu riêng Ri6 trung bình là 2-4kg. GreenHub sẽ gọi trước khi đến",
          start_time: "20/08/2019",
          end_time: "30/08/2019",
          progress: 99
        },
        {
          id: "product_005",
          title: "MẬN ANH EM - MẬN CHERRY DUY NHẤT Ở GREENHUB",
          img: "https://product.hstatic.net/1000254356/product/qrcode_da76e311d9644c3199e0ae16303e68a9_large.jpg",
          price: "199.000 đ/ kg",
          description: "Vui lòng đặt trước trên 2 kg vì 1 trái sầu riêng Ri6 trung bình là 2-4kg. GreenHub sẽ gọi trước khi đến",
          start_time: "20/08/2019",
          end_time: "30/08/2019",
          progress: 10
        },
        {
          id: "product_006",
          title: "NƯỚC MẮM TRUYỀN THỐNG PHÚ QUỐC",
          img: "https://product.hstatic.net/1000254356/product/anh_co_qr_1_d59d72b6101d44fa8e1f2cb037bb4ae4_large.jpg",
          price: "299.000 đ/ kg",
          description: "Vui lòng đặt trước trên 2 kg vì 1 trái sầu riêng Ri6 trung bình là 2-4kg. GreenHub sẽ gọi trước khi đến",
          start_time: "20/08/2019",
          end_time: "30/08/2019",
          progress: 50
        },
      ]
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    const{ products } = this.state;
    return (
      <>
        <NavbarHeader />
        <main ref="main">
          <div className="position-relative slider-custom">
            <Slider/>
          </div>
          <div className="campaign-featured">
            <Container>
              <CampaignFeatured />
            </Container>
          </div>
          <div className="list-campaign-featured">
            <h2 className="text-center font-weight-bolder my-5">Chiến dịch nổi bật</h2>
            <Container>
              <Row>
                {products.map((item, index) => (
                  <Col lg="4" key={index}>
                    <ItemCampaign item={item}/>
                  </Col>
                ))}
              </Row>
              <div className="text-center mb-5">
                <Button outline color="primary" tag="a">Xem thêm</Button>
              </div>
            </Container>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default HomePage;
