import React,  { Component } from "react";

import Countdown from "react-countdown-now";

// Random component
const Completionist = () => <span>Kết thúc!!!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a complete state
        return <Completionist />;
    } else {
        // Render a countdown
        minutes = formatTime (minutes);
        seconds = formatTime (seconds);
        return (
            <ul className="list-inline">
                <li className="text-center list-inline-item"><span>{days === 0 ? "00" : days}</span><small>Ngày</small></li>
                <li className="text-center list-inline-item"><span>{hours === 0 ? "00" : hours}</span><small>Giờ</small></li>
                <li className="text-center list-inline-item"><span>{minutes === 0 ? "00" : minutes}</span><small>Phút</small></li>
                <li className="text-center list-inline-item"><span>{seconds === 0 ? "00" : seconds}</span><small>Giây</small></li>
            </ul>
        );
    }
};

function formatTime(time) {
  return time < 10 ? "0" + time : time;
}

class CountDownCustom extends Component {
    render() {
        //1 day: 24h
        //1 hour: 60p
        //1 min: 60s
        //total: 24 * 60 * 60 = 8640 / 0.0001
        return (
            <Countdown date={Date.now() + 86400000} renderer={renderer} />
        )
    }
}

export default CountDownCustom;
